import axios from "utils/axios"

// judge user is login or not
export const userIsLoginRequest = () => axios.get("auth/info")

// user logout
export const userLogoutRequest = () => axios.get("auth/logout")

// create vcode
export const createVCode = (params) => axios.post("/createVerification", params)

// reg partner
export const registerPartner = (params) => axios.post("/reg", params)

// get all steps
export const allSteps = () => axios.get("item_type/all")

// get all brand in step1
export const allBrands = () => axios.post("brand/all")

// get the detailed items with model id
export const allItemsByModelId = (model_id, package_id, promocode) => axios.post("item/all", { model_id, package_id, promocode })

// get the detailed items with model id and promocode
export const allItemsByModelIdAndPCode = (brand_id, brandName, model_id, manuYear, promocode, package_id) => axios.post("item/all", { brand_id, brand: brandName, model: model_id, model_id, year: manuYear, promocode, package_id })

// check promocode
export const checkPCode = (promocode, controller = AbortSignal.timeout(5000)) => axios.get(`/promocode/check`, { params: { promocode }, signal: controller.signal })

// calculate the total price
export const calcTotal = (params) => axios.post("/order/calcTotal", params)

// get the order quota2
export const orderQuota2 = (params) => axios.post("/order/quota2", params)

// get the order quota2
export const createOrder = (params) => axios.post("/order/create", params)