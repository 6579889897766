
const { lazy } = require("react")

// component
// route config
export const LazyBookingDetail = lazy(() => import("components/common/BookingDetail"))
export const LazyPromotionDetail = lazy(() => import("components/PromotionDetail"))
export const LazyInstantQuoteDetail = lazy(() => import("components/InstantQuoteDetail"))
export const LazyInstantQuoteDetailAccordion = lazy(() => import("components/InstantQuoteDetailAccordion"))
export const LazyInstantQuoteDetail17Preface = lazy(() => import("components/InstantQuoteDetail17Preface"))
export const LazyInstantQuoteDetailAccordionGyeon = lazy(() => import("components/InstantQuoteDetailAccordionGyeon"))
export const LazyCommentPageDetail = lazy(() => import("components/CommentPageDetail"))
export const LazyEarnMoney = lazy(() => import("components/EarnMoney"))
export const LazyTest = lazy(() => import("components/common/Test"))
export const LazyNotFound = lazy(() => import("components/common/NotFound"))
export const LazySuccess = lazy(() => import("components/common/Success"))
export const LazyMaterialsPPF = lazy(() => import("components/MaterialsDetail/PPF"))
export const LazyMaterialsCoating = lazy(() => import("components/MaterialsDetail/Coating"))
export const LazyQuizzes = lazy(() => import("components/Quizzes"))
export const LazyOurTeam = lazy(() => import("components/OurTeam"))
export const LazyOurIdeal = lazy(() => import("components/OurIdeal"))
export const LazyResultPage = lazy(() => import("components/common/ResultPage"))
export const LazyFranchising = lazy(() => import("components/Franchising"))
export const LazyWorks = lazy(() => import("components/Works"))

// in Body
export const LazyBanner = lazy(() => import("components/Body/Banner"))
export const LazyInstantQuote = lazy(() => import("components/Body/InstantQuote"))
export const LazyProductDescription = lazy(() => import("components/Body/ProductDescription"))
export const LazyCommentPage = lazy(() => import("components/Body/CommentPage"))
export const LazyNgoPlan = lazy(() => import("components/Body/NgoPlan"))
export const LazyTeamAndBooking = lazy(() => import("components/Body/TeamAndBooking"))

// header
export const LazyAboutUs = lazy(() => import("components/Header/AboutUs"))
export const LazyService = lazy(() => import("components/Header/Service"))
export const LazyAboutMaterials = lazy(() => import("components/Header/AboutMaterials"))
export const LazyLoginPage = lazy(() => import("components/Header/LoginPage"))
export const LazyMobileMenuDetail = lazy(() => import("components/Header/MobileMenuDetail"))

// common
export const LazyButtonWithScale = lazy(() => import("components/common/ButtonWithScale"))
export const LazyButtonWithAnimation = lazy(() => import("components/common/ButtonWithAnimation"))
export const LazyBackdrop = lazy(() => import("components/common/Backdrop"))

