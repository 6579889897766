export const MaterialsPPF = "/ppf"
export const MaterialsCoating = "/product/coating"
export const PartnershipRegiseredSuccessfully = "/earnmoney/success"
export const BookingSuccess = "/reservation/success"
export const BookingInvalid = "/reservation/invalid"
export const BookingFailed = "/reservation/failed"
export const BookingCancel = "/reservation/cancel"
export const EmailsentSuccessfully = "/send_email_verification/success"
export const EmailsentFailed = "/send_email_verification/failed"
export const EmailVerifySuccessfully = "/verify_email_address/success"
export const EmailVerifyFailed = "/verify_email_address/failed"
export const ReadmorePPF = "/ppf"
export const ReadmoreGyeon = "/gyeon"
export const EarnMoney = "/earnmoney"
export const Franchising = "/franchising"
export const Preface417 = "/window-film"
export const Ideal = "/ideal"
export const Pathname_ContactUs = "/#footerWrapper"
// export const RegExp_WorksArticle = new RegExp(/\/web\/works\/article\/[0-9]+/gm)
export const RegExp_WorksArticle = /\/works\/article\/[0-9]+/gm

export const Hash_Footer = "#footerWrapper"