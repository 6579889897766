// IDs
export const ID_PartnerPersonAcc = "partnerPersonAcc"
export const ID_PartnerPersonPwd = "partnerPersonPwd"
export const ID_CustomerAcc = "customerAcc"
export const ID_VCode = "vcode"
export const ID_booking_brandName = "brandName"
export const ID_booking_carModel = "carModel"
export const ID_booking_manuYear = "manuYear"
export const ID_booking_promocode = "promocode"
export const ID_booking_package = "package"
export const ID_booking_u_name = "u_name"
export const ID_booking_u_tel = "u_tel"
export const ID_booking_u_email = "u_email"
export const ID_booking_u_lpn = "u_lpn"
export const ID_cookie_typename = "typename"
export const ID_cookie_title = "title"
export const ID_franchising_phone = "phone"
export const ID_franchising_email = "email"
export const ID_works_all = "all"

// input text
export const InputText_Phone = "聯絡電話"
export const InputText_PhoneOrEmail = "聯絡電話或電郵"
export const InputText_Email = "電郵"
export const InputText_PWD = "密碼"
export const InputText_BrandName = "車款品牌"
export const InputText_CarModel = "型號"
export const InputText_ManuYear = "年份"
export const InputText_Username = "名稱"
export const InputText_LicensePlateNo = "車牌號碼"

// input type
export const InputType_PhoneOrEmail = "poe"
export const InputType_VCodeBTN = "vcodeBtn"
export const InputType_Input = "input"
export const InputType_LoginBTN = "loginBtn"
export const InputType_Login = "login"
export const InputType_Reg = "reg"
export const InputType_Customer = "customer"
export const InputType_Partner = "partner"

// error msg
export const ErrorMsg_Common = "請填寫或選擇"
export const ErrorMsg_BrandName = "請選擇車款品牌"
export const ErrorMsg_CarModel = "請選擇型號"
export const ErrorMsg_ManuYear = "請選擇年份"
export const ErrorMsg_u_name = "請填寫名稱"
export const ErrorMsg_u_tel = "請填寫8位電話號碼"
export const ErrorMsg_u_email = "請輸入有效的電子郵件"
export const ErrorMsg_u_lpn = "請填寫車牌號碼"

// type
export const Type_Materials_Coating_None = "none"
export const Type_Materials_Coating_Items = "item"

// header
export const AboutUs = "關於我們"
export const Service = "服務"
export const AboutMaterials = "關於物料"
export const Membership = "會籍計劃"
export const Income = "賺取收入"
export const EN = "EN"
export const OurTeam = "我們的團隊"
export const OurIdea = "我們的理念"
export const PPFTitle = "PPF車漆保護膜"
export const PPF_G = "光面PPF車漆保護膜"
export const PPF_G_Short = "光面PPF"
export const PPF_A = "啞面PPF車漆保護膜"
export const PPF_A_Short = "啞面PPF"
export const PPF_Part = "PPF局部貼膜"
export const CarWin_Part = "車窗隔熱膜"
export const PPF_T = "天窗隔熱膜"
export const GYEONTitle = "GYEON Q2鍍膜"
export const GYEON_Coating = "Coating 鍍膜"
export const GYEON_D = "擋風玻璃鍍膜"
export const GYEON_PPF = "PPF專用鍍膜"
export const Anti = "消毒及抗菌"
export const PPF = "PPF"
export const COATING = "COATING"
export const COATING_service = "量子抗菌塗層"
export const WINDOW_Protective = "車窗隔熱膜"
export const Glass = "玻璃隔熱膜"
export const UserPanel = "合作夥伴平台"
export const Path_AboutUs = "aboutus"
export const Path_Service = "service"
export const Path_AboutMaterials = "aboutmaterials"

// instant quote 
export const PricingAndBooking = "報價及預約"
export const PriceLowerTo = "價錢低至"
export const PriceUpTo = "價錢視乎"
export const PPFThickness = "PPF厚度"
export const WorkingTime = "施工時間"
export const PreventEffectively = "有效阻隔"
export const Durability = "耐久度"

// login page
export const LoginPageTitle = "合作夥伴登入"
export const GetVCodeBySMS = "以SMS獲取驗證碼"
export const Customer = "顧客"
export const Partner = "合作夥伴"
export const VCode = "驗證碼"
export const Person = "個人"
export const Company = "公司"

// our team
export const OurTeamPreText_Admin = "九龍灣總店"
export const OurTeam_AdminTeam = "管理團隊"
export const OurTeam_ExecTeam = "施工團隊"
export const OurTeam_CommunityTeam = "社企學員"

// partnership
export const Verify = "驗證"
export const VerifyEmail = "驗證電郵"
export const ToBeOurPartner = "加入成為合作夥伴"
export const SetPwd = "設定密碼"
export const BasicInfo = "基本資料"
export const AccountInfo = "收款帳戶資料"

// homepage popup ad
export const BottomPlaceholder = "網頁下方領取優惠碼"
export const Pickup = "領取"
export const Off10Percent = "限時95折"
export const Promocode = "優惠碼"
export const Off10PercentAfterPickup = "使用限時95折優惠"
export const Key_cookie_promocode = "dc"
export const PickupInfo = "已成功領取！"
export const Value_promocode = "MRWP95"
export const TipsInfo = "請於限時內預約，將可自動獲得折扣。"

// homepage
export const Title_instant_quote = "即時報價"

// booking
export const Error_booking_failtips = "請聯繫客戶服務熱線 3708 7275"
export const Phd_step1_selectBrand = "請選擇車款品牌"
export const Phd_your_choice = "你的選擇是："
export const Phd_input_promocode = "請輸入優惠碼"
export const Phd_price = "價格："
export const Phd_booking_date = "預約日期(日/月/年):"
export const Phd_booking_datetime = "預約日期及時段(日/月/年)"
export const Phd_booking_datetimeNoFormat = "預約日期及時段:"
export const Phd_booking_pickupDate = "最快取車日期(日/月/年)"
export const Phd_booking_pickupDateFull = "已滿，請改預約日期。"
export const Phd_booking_userInfo = "聯絡資料"
export const Phd_booking_PromotionInfo = "本人願意接收來自Mr. Wrapper的宣傳資訊"
export const Phd_booking_IamAgreed = "本人同意"
export const Phd_booking_useOfPolicy = "《Mr. Wrapper 服務使用條款》"
export const Phd_booking_confirmAndPay = "確認並支付訂金"
export const Phd_booking_supportUs = "支持Mr.Wrapper，可以為更多社企學員帶來工作機會!"
export const Phd_booking_buySouvenirs = "購買紀念品"
export const Phd_booking_Souvenirs100 = "擴香石慈善義賣 - $100"
export const Phd_booking_Souvenirs0 = "我再想想"
export const Phd_booking_Souvenirs100_phd = "屆時親臨門市揀選一款擴香石（款式會不時更新），感謝支持NGO社會服務計劃！"
export const Phd_booking_Souvenirs0_phd = "你仍可以在享用Mr.Wrapper服務後在門市購買紀念品！"
export const Phd_booking_Paid = "支付及預約"
export const Phd_booking_lpn = "新車未到請填寫 TBC"

// booking compare
export const Compare_inDetail = "詳細比較"
export const Staff_tech = "由技術人員負責"
export const Staff_comm = "由社企學員負責"
export const HandWrapped = "手工包邊裁膜"
export const Contain = "包含"
export const Hyphen = "-"
export const Mil8P2 = "8.2mil"
export const Mil7P5 = "7.5mil"
export const Mil6P8 = "6.8mil"
export const Year3 = "3年"
export const Year2 = "2年"
export const Year1 = "1年"
export const DeepPolish = "深層研磨拋光"
export const BaseScratchRemoving = "基本花痕移除"
export const CoatingNano = "納米修復複合式鍍膜"
export const Coating9HPlus = "複合式鍍膜 9H+"
export const Coating9H = "單層鍍膜 9H"
export const CoatingThickness1P2 = "~1.2qm"
export const CoatingThicknessP5 = "~0.5qm"
export const CoatingThicknessP3 = "~0.3qm"
export const CoatingWaterEffectTop = "TOP 跣水光滑塗層"
export const CoatingWaterEffectSkin = "SKIN 跣水光滑塗層"
export const Validity48M = "長達 48個月"
export const Validity36M = "長達 36個月"
export const Validity24M = "長達 24個月"
export const Validity18M = "長達 18個月"
export const Validity12M = "長達 12個月"
export const Column_PPFThickness = "PPF厚度"
export const Column_Protection = "保護性"
export const Column_Gloss = "光澤度"
export const Column_WaterEffect = "跣水效果"
export const Column_Antifouling = "防污能力"
export const Column_Antiyellowing = "耐黃能力"
export const Column_Selfhealing = "自我修復力"
export const Column_HandFoam = "人手泡沫洗車"
export const Column_Coating180 = "量子抗菌塗層（180天）"
export const Column_CoatingInfinite = "量子抗菌塗層"
export const Column_Gyeon = "GYEON擋風玻璃鍍膜"
export const Column_PPFHeadlight = "PPF頭燈保護膜"
export const Column_MService = "保養維護服務"
export const Column_Coating = "鍍膜塗層"
export const Column_CoatingThickness = "鍍膜厚度"
export const Column_CoatingWaterEffect = "跣水塗層"
export const Column_Validity = "有效期"
export const Column_CoatingGlossEffect = "光澤效果"
export const Column_Dustproof = "防塵程度"
export const Column_AntiUV = "防紫外線"
export const Column_ScratchReparing = "車漆花痕修復"
export const Column_InsulationThickness = "隔熱膜厚度"
export const Column_InsulationEffect = "隔熱效果"
export const Column_GYEON_PPF = "GYEON PPF專用鍍膜"

// package name
export const Package_allInOne = "ALL IN ONE"
export const Package_plus = "PLUS"
export const Package_flash = "FLASH"
export const Package_mattePlus = "MATTE+"
export const Package_matte = "MATTE"
export const Package_syncro = "SYNCRO"
export const Package_syncroPlus = "SYNCRO+"
export const Package_pure = "PURE"
export const Package_panoramic = "全景"
export const Package_half_sunroof = "半天窗"

// result of quiz
export const RcmdService_phd = "Mr. Wrapper根據您的情況為你推薦的首選服務是："
export const RcmdSubService_phd = "Mr. Wrapper根據您的情況為你推薦的次選服務是："
export const ReservationNow = "立即預約"
export const PPF_GA = "光面／啞面 PPF"
export const Coating = "鍍膜"

// works
export const Text_Works = "施工作品"
export const Text_PrevPage = "上一頁"
export const Text_MoreArticle = "更多文章"

// tabs title
export const Tabs_Title_Homepage = '主頁 - Mr. Wrapper'
export const Tabs_Title_Commentd = '星級用家分享'
export const Tabs_Title_Earnmoney = '成為合作夥伴'
export const Tabs_Title_Earnmoney_Success = '註冊成功/合作夥伴'
export const Tabs_Title_Gyeon = 'GYEON 鍍膜'
export const Tabs_Title_Ppf = 'PPF 保護膜'
export const Tabs_Title_Quizzes = '線上快速測試'
export const Tabs_Title_Reservation_Cancel = '預約取消'
export const Tabs_Title_Reservation_Failed = '預約失敗'
export const Tabs_Title_Reservation_Invalid = '無效預約'
export const Tabs_Title_Reservation_Success = '預約成功'
export const Tabs_Title_SendEmailVeri_Failed = '發送驗證電郵失敗'
export const Tabs_Title_SendEmailVeri_Success = '成功發送驗證電郵'
export const Tabs_Title_VeriEmailAddr_Failed = '驗證電郵地址失敗'
export const Tabs_Title_VeriEmailAddr_Success = '驗證電郵地址成功'
export const Tabs_Title_Works = '施工作品'
export const Tabs_Title_About = '我們的團隊'
export const Tabs_Title_Ideal = '我們的理念'
export const Tabs_Title_Preface_Booking_17 = '玻璃隔熱膜'
export const Tabs_Title_Booking_2 = '光面PPF'
export const Tabs_Title_Booking_3 = '啞面PPF'
export const Tabs_Title_Booking_4 = 'Coating 鍍膜'
export const Tabs_Title_Booking_5 = 'PPF專用鍍膜'
export const Tabs_Title_Booking_6 = '擋風玻璃鍍膜'
export const Tabs_Title_Booking_9 = '天窗隔熱膜'
export const Tabs_Title_Booking_10 = 'PPF局部貼膜'
export const Tabs_Title_Booking_15 = '量子抗菌塗層'
export const Tabs_Title_Booking_17 = '車窗隔熱膜'


// common
export const Logout = "登出"
export const Login = "登入"
export const Readmore = "詳情"
export const Homepage = "首頁"
export const Return = "返回"
export const Next = "下一步"
export const Confirm = "確認"
export const Default = "Nothing"
export const Selection = "選擇"
export const Close = "關閉"
export const Requied = "必須填寫"
export const ContactUs = "聯絡我們"
export const LearnMore = "了解更多"
export const All = "所有"