import { Spinner } from 'react-bootstrap'

export default function MySpinner({
  styles,
  spinnerStyles,
  variant = "primary"
}) {

  return (
    <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", ...styles }} >
      <Spinner animation="border" variant={variant} style={{ width: 48, height: 48, ...spinnerStyles }} />
    </div>
  )
}
