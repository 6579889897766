import { memo } from 'react'
import { Link } from 'react-router-dom'
import { lineYB } from 'config/defaultData'
import styles from "./index.module.css"

function Titlebar({
    hidePrevBtn,
    prevBtnText,
    prevBtnTo,
    prevBtnColor,
    title1,
    title1FontSize,
    title2,
    title3,
    titleColor,
    lineSrc,
    lineWidth,
    lineHeight,
    positionStart,
    noPaddingTop,
    myStyles
}) {

    return (
        <div className={[styles.titlebar].join(" ")} style={{ alignItems: positionStart ? "flex-start" : "center", paddingTop: noPaddingTop && 0, ...myStyles }} >
            {
                !hidePrevBtn && (
                    <div className={[styles.backToHome].join(" ")}>
                        <Link to={prevBtnTo || "/"} style={{ color: prevBtnColor || "inherit" }} >
                            <span className={[styles.icon].join(" ")}>
                                <i className="fa-solid fa-chevron-left"></i>
                            </span>
                            <span className={[styles.text].join(" ")}>
                                {prevBtnText || "首頁"}
                            </span>
                        </Link>
                    </div>
                )
            }

            <div className={[styles.title].join(" ")} style={{ alignItems: positionStart ? "flex-start" : "center" }} >
                <span style={{ color: titleColor || "inherit", fontSize: title1FontSize }} dangerouslySetInnerHTML={{ __html: title1 }}></span>
                {title2 ? <span style={{ color: titleColor || "inherit" }} >{title2}</span> : false}
                {title3 ? <span style={{ color: titleColor || "inherit" }} >{title3}</span> : false}
                <span className={[styles.icon].join(" ")} style={{ backgroundImage: `url(${lineSrc || lineYB})`, width: lineWidth, height: lineHeight }} ></span>
            </div>
        </div>
    )
}

/**
 * @param {Boolean} hidePrevBtn default to undefined
 * @param {String} prevBtnText default to "首頁"
 * @param {String} [prevBtnTo='/'] default to "/"
 * @param {String} prevBtnColor default to "inherit"
 * @param {String} [title1] option
 * @param {Number} [title1FontSize] title1 font-size
 * @param {String} [title2] option
 * @param {String} [title3] option
 * @param {String} [titleColor] option, default to "inherit"
 * @param {String} lineSrc default to lineYB (image path)
 * @param {String} lineWidth the width of the dividing icon
 * @param {String} lineHeight the height of the dividing icon
 * @param {Boolean} positionStart align to start
 * @param {Boolean} noPaddingTop hide padding-top
 * @param {Object} myStyles css style object
 */
export default memo(Titlebar)