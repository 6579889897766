import ButtonWithScale from 'components/common/ButtonWithScale'
import { lineWB, teamAndBookingBG2, teamAndBookingLowPartBG, teamAndBookingPPF } from 'config/defaultData'
import styles from './index.module.css'
import { memo } from 'react'

function Booking() {

    return (
        <div
            className={[styles.bookingWrapper].join(' ')}
        // style={{ backgroundImage: `url(${teamAndBookingLowPartBG})` }}
        >
            {/* <img src={teamAndBookingBG2} alt="missing" /> */}

            <div className={[styles.booking].join(' ')} >
                <div className={[styles.bookingTitlebar].join(' ')} >
                    <span className={[styles.title].join(' ')}>
                        做PPF定鍍膜？
                    </span>
                    <span className={[styles.subtitle].join(' ')} >
                        邊款適合你？你問我答你！
                    </span>
                    <span className={[styles.icon].join(' ')} style={{ backgroundImage: `url(${lineWB})` }}></span>
                </div>
                <div className={[styles.bookingDetail].join(' ')} >
                    沒有最好，只有最適合的服務，如果您還在猶豫要選擇什麼護理時，我們的線上快速測定能幫到您，只需要回答幾條用車習慣問題便能為您歸納出方向。
                </div>
                <div className={[styles.bookingBtn].join(' ')} >
                    <ButtonWithScale text={"立即預約"} to="/ppf" />
                    <ButtonWithScale text={"開始測試"} to="/quizzes" />
                </div>
            </div>
            <div className={[styles.ppfPic].join(' ')} >
                <img src={teamAndBookingPPF} alt="missing" />
            </div>
        </div>
    )
}

/**
 * Booking component dividing from TeamAndBooking component in the homepage
 */
export default memo(Booking)