import React, { memo } from 'react'
import Backdrop from '../Backdrop'
import ButtonWithScale from '../ButtonWithScale'
import { Confirm } from 'config/text'
import style from "./index.module.css"


function Toast({
    hideADToast,
    pickUpInfo,
    promocode,
    tipsInfo,
    bgColor,
    fontColor
}) {
    
  return (
    <>
        <div className={[style.toast].join(" ")} style={{backgroundColor: bgColor }} >
            <div className={[style.pickUpInfo].join(" ")} style={{color: fontColor}}>
                {pickUpInfo}
            </div>
            <div className={[style.promocode].join(" ")}>
                {promocode}
            </div>
            <div className={[style.tipsInfo].join(" ")} style={{color: fontColor}}>
                {tipsInfo}
            </div>
            <div className={[style.confirmBtn].join(" ")}>
                <ButtonWithScale text={Confirm} onClick={hideADToast} />
            </div>
        </div>

        <Backdrop />
    </>
  )
}

/**
 * @param {Function} hideADToast hide the confirm popup
 * @param {String} pickUpInfo title info
 * @param {String} promocode promocode
 * @param {String} tipsInfo tips info
 * @param {String} bgColor background color
 * @param {String} fontColor text color
 */
export default memo(Toast)
