import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'utils/axios'
import Titlebar from 'components/common/Titlebar'
import ItemCard from '../ItemCard'
import { lineYW } from 'config/defaultData'
import { Text_MoreArticle, Text_PrevPage, Text_Works } from 'config/text'
import styles from './index.module.css'
import { isEqual } from 'lodash'

export default function Article() {

    const location = useLocation()
    const { title, subtitle, content, total, id } = location.state

    const [data, setData] = useState({ more: [] })
    const navigate = useNavigate()

    // handler
    const prevHandler = useCallback(() => navigate(-1), [])
    const scrollToTop = useCallback(() => window.scrollTo(0, 0), [])

    useEffect(() => {
        window.scrollTo(0, 0)

        axios.post(`/manager/post/view/${id}`).then(resp => setData({ more: resp.data.data.data[0].more }))
    }, [id])

    useEffect(() => {
        document.title = title + " " + subtitle
    })

    return (
        <div className={styles.article} >

            <div className={styles.bg} style={{ backgroundImage: `url("/web/uploads/images/franchising/v3.jpg?v=0.0.6")` }}></div>
            <div className={styles.mask}></div>

            <div className={styles.wrapper}>
                <span className={styles.prev} onClick={prevHandler} >{Text_PrevPage}</span>
                <Titlebar title1={Text_Works} lineSrc={lineYW} titleColor={"white"} hidePrevBtn positionStart noPaddingTop />
                <div className={styles.content} >
                    <div className={styles.title}>{title}</div>
                    <div className={styles.subtitle}>{subtitle}</div>
                    <div className={styles.main} dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
                {
                    !isEqual(data?.more?.length, 0) && (
                        <div className={styles.recommend}>
                            <div className={styles.holder}>{Text_MoreArticle}</div>
                            <div className={styles.rec}>
                                {
                                    data?.more?.map(
                                        i => (
                                            <Link key={i.id} to={`/works/article/${i.id}`} state={{ ...i, total: total }} onClick={scrollToTop} >
                                                <ItemCard {...i} />
                                            </Link>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    )
                }

                <span className={styles.prev} onClick={prevHandler}>{Text_PrevPage}</span>
            </div>
        </div>
    )
}