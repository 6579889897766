import { memo, useEffect } from 'react'
import Body from 'components/Body'
import { Tabs_Title_Homepage } from 'config/text'
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css"

function Home() {

  useEffect(() => {
    document.title = Tabs_Title_Homepage
  }, [])

  return (
    <div className="view">
      <Body />
    </div>
  )
}

export default memo(Home)
