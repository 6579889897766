import { memo } from 'react'
import SimpleImageSlider from 'react-simple-image-slider'
import Backdrop from '../Backdrop'
import { BottomPlaceholder } from 'config/text'
import style from "./index.module.css"


function PopupAD({
    images,
    closeBtnOnClick
}) {

  return (
    <>
        <div className={[style.popupAD].join(" ")} >
            <SimpleImageSlider 
                width={"100%"}
                height={"100%"}
                images={images}
                showBullets={ images.length === 1 ? false : true}
                showNavs={images.length === 1 ? false : true}
                slideDuration={0.2}
            />
            <span 
                className={[style.closeBtn_ad].join(" ")}
                onClick={closeBtnOnClick}
            />
            <div className={[style.bottomPlaceholder].join(" ")}>
                <span className={[style.downward_icon].join(" ")}></span>
                <span className={[style.placeholderText]} >{BottomPlaceholder}</span>
            </div>
        </div>

        <Backdrop />
    </>
  )
}

/**
 * for ref: https://github.com/kimcoder/react-simple-image-slider
 * @param {Array} images ad images to show in homepage
 * @param {Function} closeBtnOnClick the onClick handler of close btn
 * @returns 
 */
export default memo(PopupAD)
