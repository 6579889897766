import { memo, useState } from 'react'
import Countdown from 'react-countdown'
import ButtonWithScale from '../ButtonWithScale'
import { setCookies } from 'utils/func'
import { Off10Percent, Off10PercentAfterPickup, Pickup, Promocode } from 'config/text'
import { iconAfterPickup, iconAfterPickupForMobile } from 'config/defaultData'
import styles from "./index.module.scss"


function PopupADBanner({
    closeBtnOnClick,
    hideAD,
    showADToast,
    isPickup,
    setIsPickup
}) {

    const [dateForCountdown, setDateForCountdown] = useState(Date.now() + 3600 * 1000)
    const [onMobile, setOnMobile] = useState(window.innerWidth <= 991.98)

    // Renderer callback with condition
    const renderer = ({ formatted }) => 
        <span style={{fontWeight: 700}} >{formatted.minutes}分{formatted.seconds}秒</span>;
    
  return (
    <div className={[styles.popupADBanner].join(" ")} style={{gridTemplateAreas: isPickup ? `'. d a a a a b b b c .'` : `'. a a a a a b b b c .'`  }} >
        {
            isPickup ? (
                <>
                    <span className={[styles.iconAfterPickup]} style={{backgroundImage: `url(${onMobile ? iconAfterPickupForMobile : iconAfterPickup})`}} ></span>
                    <div className={[styles.off10PercentAfterPickup].join(" ")} >{Off10PercentAfterPickup}</div>
                </>
            ) : (
                <ButtonWithScale onClick={() => {hideAD(); showADToast(); setCookies(); setIsPickup(true)}} >
                    <span style={{fontWeight: 500, fontSize: 14}} >
                        {Pickup}
                    <span style={{fontWeight: 900, fontSize: 14}}>{Off10Percent}</span>    
                        {Promocode}
                    </span>
                </ButtonWithScale>
            )
        }
        
        
        <span className={[styles.countDown].join(" ")}>
            <span className={[styles.remainingTime]} >尚餘</span>
            <Countdown 
                date={dateForCountdown}
                renderer={renderer}
                zeroPadTime={2}
                // onStop={closeBtnOnClick}
            />
        </span>

        <span 
            className={[styles.closeBtn_popupBanner]} 
            onClick={closeBtnOnClick}
        />
    </div>
  )
}

/**
 * Countdown component for ref: https://github.com/ndresx/react-countdown
 * @param {Function} closeBtnOnClick the onClick handler of close btn
 * @param {Function} hideAD hide the popup ad
 * @param {Function} showADToast show the confirm popup
 */
export default memo(PopupADBanner)