import { Suspense, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { isEqual } from "lodash";
import Header from "./components/Header";
import MySpinner from "components/common/MySpinner";
import Footer from "components/Footer";
import { Hash_Footer } from "config/pathname";
import route from "config/route";
import "./index.css"

function App() {

  const routes = useRoutes(route)
  // deprecated route
  {/* <Route path="instantquote" >
    <Route path="gyeon" element={ <InstantQuoteDetailAccordionGyeon/> } />
    <Route path="ppf" element={ <InstantQuoteDetailAccordion/> } />
  </Route> */}
  {/* <Route path="materials" >
    <Route path="ppf" element={ <MaterialsPPF/> } />
  </Route> */}
  {/* <Route path="ourteam" element={ <OurTeam/> } /> */ }


  const [sHeight, setSHeight] = useState(document.body.scrollHeight)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (sHeight !== document.body.scrollHeight) setSHeight(document.body.scrollHeight)
    })
    const contactUsInFooter = document.querySelectorAll(".footerWrapper .footer .row .section")

    for (let i = 0; i < contactUsInFooter.length; i++) {

      contactUsInFooter[i].addEventListener("click", () => {
        for (let j = 0; j < contactUsInFooter.length; j++) {
          contactUsInFooter[j].classList.remove('hover')
        }
      })
    }

  }, [])

  useEffect(() => {
    const footerWrapper = document.querySelector(Hash_Footer)

    if (isEqual(window.location.hash, Hash_Footer)) {
      footerWrapper.scrollIntoView({
        block: "end",
        behavior: "smooth"
      })
      return
    }

  }, [sHeight])



  return (
    <div className="app" >
      <div id="headerWrapper" className="headerWrapper">
        <Header />
      </div>

      <Suspense fallback={<MySpinner />}>
        {routes}
      </Suspense>

      <div id="footerWrapper" className="footerWrapper" >
        <Footer />
      </div>
    </div>
  )
}

export default App;
