import styles from './index.module.css'

export default function ItemCard({
    cover,
    title,
    subtitle
}) {

    return (
        <div
            className={[styles.itemCard].join(' ')}
            style={{ backgroundImage: `url(${cover})` }}
        >
            <div className={[styles.title].join(' ')}>{title}</div>
            <div className={[styles.subtitle].join(' ')}>{subtitle}</div>
        </div>
    )
}