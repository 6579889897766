// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  if(e.target === e.currentTarget) {
    e.preventDefault();
    e.stopPropagation()
  }
  e.stopImmediatePropagation()
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
export function disableScroll(element=window) {
  element.addEventListener('DOMMouseScroll', preventDefault, {once: false, useCapture: false}); // older FF
  element.addEventListener(wheelEvent, preventDefault, {once: false, useCapture: wheelOpt}); // modern desktop
  element.addEventListener('touchmove', preventDefault, {once: false, useCapture: wheelOpt}); // mobile
  element.addEventListener('keydown', preventDefaultForScrollKeys, {once: false, useCapture: false});
}

// call this to Enable
export function enableScroll(element=window) {
  element.removeEventListener('DOMMouseScroll', preventDefault, {once: false, useCapture: false});
  element.removeEventListener(wheelEvent, preventDefault, {once: false, useCapture: wheelOpt}); 
  element.removeEventListener('touchmove', preventDefault, {once: false, useCapture: wheelOpt});
  element.removeEventListener('keydown', preventDefaultForScrollKeys, {once: false, useCapture: false});
}